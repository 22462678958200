<template>
  <div class="nav-container">
    <keep-alive>
      <civdrawer v-if="openCivs" />
      <mapslist v-if="openMaps" />
      <patchnotes v-if="openPatchnotes" />
    </keep-alive>
    <div
      title="Civilizations"
      class="nav-button civ-drawer-button"
      @click="openCivs = !openCivs"
      @click.stop="openMaps = openPatchnotes = false"
    ></div>
    <div
      title="Current Ranked Maps"
      class="nav-button maps-button"
      @click="openMaps = !openMaps"
      @click.stop="openCivs = openPatchnotes = false"
    ></div>
    <a
      title="Join the PvP Discord!"
      href="https://discord.gg/ecMmsTE"
      target="_blank"
      rel="noopener"
    >
      <div class="nav-button discord-button"></div>
    </a>
  </div>
</template>
<script>
export default {
  name: "Navigation",
  data() {
    return {
      openCivs: false,
      openMaps: false,
      openPatchnotes: false
    }
  },
  methods: {
    onClickOutside() {
      this.openCivs = false
      this.openMaps = false
      this.openPatchnotes = false
    },
    bindClickOutside() {
      document.addEventListener("click", this.onClickOutside)
    },
    unbindClickOutside() {
      document.removeEventListener("click", this.onClickOutside)
    }
  },
  mounted() {
    this.bindClickOutside()
  },
  beforeDestroy() {
    this.unbindClickOutside()
  }
}
</script>
<style scoped lang="scss">
.nav-container {
  padding-right: 1rem;
  position: relative;
  z-index: 999;

  .nav-button {
    width: 85px;
    height: 88px;
    position: relative;
    z-index: 1;

    &.civ-drawer-button {
      background-image: url("~@/assets/img/List-Normal.webp");

      &:hover {
        cursor: pointer;
        background-image: url("~@/assets/img/List-Hover.webp");
      }
    }

    &.maps-button {
      background-image: url("~@/assets/img/MapsList-Normal.webp");

      &:hover {
        cursor: pointer;
        background-image: url("~@/assets/img/MapsList-Hover.webp");
      }
    }


    &.discord-button {
      background-image: url("~@/assets/img/DiscordV2-Normal.webp");

      &:hover {
        cursor: pointer;
        background-image: url("~@/assets/img/DiscordV2-Hover.webp");
      }
    }
  }
}
</style>
