<template>
  <div class="techtree">
    <div class="techtree-tab-selectors">
      <techtreetabselector tab="military" />
      <techtreetabselector tab="economy" />
      <techtreetabselector tab="utility" />
    </div>
    <img class="techtree-civ-icon" :src="require(`../assets/img/civ/${civ}.webp`)" />
    <h1 class="techtree-header text-heading">Technology</h1>
    <div class="techtree-age-container">
      <techtreeage :age="1" />
      <techtreeage :age="2" />
      <techtreeage :age="3" />
      <techtreeage :age="4" />
    </div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <div class="tech-tree-cover">
      <span class="text-heading">These stats are for Champion Skirmish and PvP modes only</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "TechTree",
  props: {
    civ: { type: String, required: true }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style scoped lang="scss">
.techtree {
  position: relative;
  margin: auto;
  padding-left: 74px;
  padding-right: 105px;

  .techtree-tab-selectors {
    display: flex;
    flex-direction: row;
    z-index: 1;

    position: absolute;
    top: 27px;
    right: 136px;

    .techtree-tab-selector:not(:last-child) {
      margin-right: 3px;
    }
  }

  .techtree-header {
    position: absolute;
    z-index: 1;
    left: 237px;
    top: 62px;
  }

  .techtree-civ-icon {
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    left: 87px;
    top: 12px;
  }

  .techtree-tab {
    z-index: 0;
  }

  .techtree-age-container {
    position: absolute;
    left: 0;
    top: 137px;

    .techtree-age:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  .tech-tree-cover {
    position: absolute;
    bottom: 128px;
    left: (1024px - 983px) / 2 + 74px;
    width: 983px;
    height: 86px;

    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("~@/assets/img/CoverCropped.webp");

    .text-heading {
      font-size: 20px;
      line-height: 83px;
    }
  }
}
</style>
