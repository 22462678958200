<template>
  <transition name="fade">
    <div class="civ-drawer" @click.stop>
      <h4 class="text-heading">Civilizations</h4>
      <ul class="civ-drawer-list">
        <li v-for="(_, civ) in C.civs" :key="civ" @click="$router.push('/' + civ)" :class="{hidden: C.hiddenCivs.includes(civ)}">
          <img :src="icon(civ)" class="civ-drawer-list-img" />
          {{ C.civNames[civ] }}
        </li>
        <li v-for="(_, civ) in C.hiddenCivs" :key="civ">Soon...</li>
      </ul>
    </div>
  </transition>
</template>
<script>
export default {
  name: "CivDrawer",
  watch: {
    $route() {
      this.$forceUpdate()
    }
  },
  methods: {
    icon: function(civ) {
      return this.$route.name.startsWith(civ)
        ? require(`../assets/img/civ/${civ}.webp`)
        : require(`../assets/img/civ/${civ}-Unselected.webp`)
    }
  }
}
</script>
<style scoped lang="scss">
.civ-drawer {
  position: absolute;
  background-image: url("~@/assets/img/civ/CivsListBG.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 316px;
  height: 620px;
  min-width: 300px;
  min-height: 620px;
  margin-left: 90px;
  z-index: 0;

  .civ-drawer-list {
    margin-top: 23px;
    margin-bottom: 23px;
    list-style: none;
    text-align: left;
    padding-top: 3px;
    padding-left: 28px;
    padding-right: 32px;

    &:hover {
      cursor: pointer;
    }

    li {
      color: $font-color;
      font-size: 20px;
      text-shadow: 2px 0 2px #192b33;
      font-family: "Ashley Crawford MT";
      padding: 3px 8px;
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    li:not(:last-child) {
      margin-bottom: 9px;
    }

    li:nth-child(3),
    li:nth-child(6) {
      margin-bottom: 10px;
    }

    li:nth-child(4) {
      margin-bottom: 11px;
    }

    .civ-drawer-list-img {
      width: 50px;
      height: 50px;
      margin-right: 0.5rem;
      vertical-align: middle;
    }
  }

  .text-heading {
    padding-top: 18px;
    font-size: 18px;
    padding-right: 11px;
  }
}
</style>
