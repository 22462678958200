<template>
  <div
    class="techtree-tab-selector"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @mousedown="clicked = true"
    @mouseup="clicked = false"
    @click="onClick(tab)"
  >
    <keep-alive>
      <img class="techtree-tab-selector-background" :src="tabBackground" />
    </keep-alive>
    <span class="techtree-tab-selector-text text-category">{{
      C.tabs[tab].text
    }}</span>
  </div>
</template>
<script>
export default {
  name: "TechTreeTabSelector",
  props: {
    tab: { type: String, required: true }, // economy, military or utility
  },
  data() {
    return {
      hover: false,
      clicked: false,
    }
  },
  watch: {
    $route() {
      this.$forceUpdate()
    },
  },
  computed: {
    selected: function () {
      return this.$route.path.endsWith(this.tab)
    },
    tabBackground: function () {
      if (this.selected)
        return require(`../assets/img/${this.C.tabs[this.tab].selected}`)
      else if (this.clicked)
        return require(`../assets/img/${this.C.tabs[this.tab].clicked}`)
      else if (this.hover)
        return require(`../assets/img/${this.C.tabs[this.tab].hover}`)
      else return require(`../assets/img/${this.C.tabs[this.tab].unselected}`)
    },
  },
  methods: {
    onClick: function (tab) {
      if (!this.selected) {
        this.$router.push(tab)
      }
    },
  }
}
</script>
<style scoped lang="scss">
.techtree-tab-selector {
  position: relative;
  height: 90px;
  width: 128px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  .techtree-tab-selector-background {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .techtree-tab-selector-text {
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
  }
}
</style>
