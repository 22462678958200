<template>
  <div class="techtree-container">
    <navigation />
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: "TechTreeContainer"
}
</script>
<style scoped lang="scss">
.techtree-container {
  display: flex;
  position: relative;
  padding: 2rem;
}
</style>
