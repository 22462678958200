<template>
  <div class="techtree-age">
    <img
      class="techtree-age-background"
      :src="require('../assets/img/Art/UserInterface/CapitalTech/AgeTabBackground_ua.webp')"
    />
    <img
      class="techtree-age-icon"
      :src="require(`../assets/img/Art/UserInterface/CapitalTech/AgeUpIcon${age}Active_ua.webp`)"
    />
  </div>
</template>
<script>
export default {
  name: "TechTreeAge",
  props: {
    age: { type: Number, required: true }
  }
}
</script>
<style scoped lang="scss">
.techtree-age {
  position: relative;
  width: 128px;
  height: 128px;

  .techtree-age-icon {
    position: absolute;
    top: 33px;
    left: 15px;
  }
}
</style>
