<template>
  <transition name="fade">
    <div class="legal-notice" v-if="open">
      <p>
        Age of Empires Online © Microsoft Corporation. This website was created under Microsoft's
        <a
          href="https://www.xbox.com/en-us/developers/rules"
          target="_blank"
        >Game Content Usage Rules</a> using assets from Age of Empires Online, and it is not endorsed by or affiliated with Microsoft.
      </p>
      <div class="close-button-container">
        <button @click="close" class="close-button" title="Close">&times;</button>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "LegalNotice",
  data() {
    return {
      open: false
    }
  },
  methods: {
    close() {
      this.open = false

      const d = new Date()
      d.setTime(d.getTime() + 365 * 10 * 24 * 60 * 60 * 1000) // 10 years from now
      const expires = "expires=" + d.toUTCString()

      document.cookie = "legal=true;" + expires + ";path=/"
    }
  },
  mounted() {
    const cookies = document.cookie.split(";").map(s => s.trim())
    this.open = cookies.indexOf("legal=true") == -1
  }
}
</script>
<style scoped lang="scss">
.legal-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $background-color-legal-notice;
  display: flex;
  flex-direction: row;
  z-index: 999;

  & > p {
    padding: 0 2rem;
    margin-left: auto;
    margin-right: auto;
  }

  .close-button-container {
    position: absolute;
    right: 0;
    top: 0;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 28px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
