<template>
    <div id="app">
        <router-view/>
        <keep-alive>
            <tooltip v-if="hover"
                :title="item.title || item.tech || 'undefined'"
                :icon="item.iconHover"
                :description="item.desc"
                :type="item.type"
                :cost="item.cost"
                :upgrades="item.up"
                :stats="item.stats"
                :style="'top:' + y + 'px; left:' + x + 'px;'"
                @mousemove="onMouseMove"
                ref="tooltip"/>
        </keep-alive>
        <legalnotice />
    </div>
</template>
<script>
    export default {
        name: 'App',
        data() {
            return {
                hover: false,
                x: 0,
                y: 0,
                item: null
            }
        },
        methods: {
            onMouseMove(event, item) {
                this.onHover(item)
                if(this.hover) {
                    window.Vue.nextTick(() => {
                        this.x = event.clientX + 15 - this.$el.parentElement.getBoundingClientRect().left

                        if(this.x + this.$el.parentElement.getBoundingClientRect().left + this.$refs.tooltip.$el.clientWidth + 6 > document.body.clientWidth) {
                            this.x = event.clientX - this.$refs.tooltip.$el.clientWidth - 15 - this.$el.parentElement.getBoundingClientRect().left - 6
                        }

                        if(this.x < 0) {
                            this.x = window.innerWidth - this.$refs.tooltip.$el.clientWidth - this.$el.parentElement.getBoundingClientRect().left - 6
                        }

                        this.y = event.clientY + 15 - this.$el.parentElement.getBoundingClientRect().top

                        if(this.y + this.$el.parentElement.getBoundingClientRect().top + this.$refs.tooltip.$el.clientHeight + 6 > window.innerHeight) {
                            this.y = event.clientY - this.$refs.tooltip.$el.clientHeight - 15 - this.$el.parentElement.getBoundingClientRect().top
                        }

                        if(this.y < 0) {
                            this.y = window.innerHeight - this.$refs.tooltip.$el.clientHeight - this.$el.parentElement.getBoundingClientRect().top - 6
                        }
                    })
                }
            },
            onHover(item) {
                this.hover = item != null
                this.item = item
            },
        },
        mounted() {
            window.GlobalEvent.$on('TechTreeItemHover', this.onHover)
            window.GlobalEvent.$on('TechTreeItemHoverMove', this.onMouseMove)
        },
        beforeDestroy() {
            window.GlobalEvent.$off('TechTreeItemHover', this.onHover)
            window.GlobalEvent.$off('TechTreeItemHoverMove', this.onMouseMove)
        }
    }
</script>
<style lang="scss">
@import "./sass/app.scss";

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    display: flex;
    flex-direction: column;
}

.tooltip {
    position: absolute;
    z-index: 999;
}
</style>
