<template>
  <transition name="fade">
    <div class="patch-notes-container" @click.stop>
      <button
        class="control-button button-left"
        :disabled="index <= 0"
        @click="index--"
      ></button>
      <div class="patch-notes-image-container">
        <h4 class="text-heading">Patch Notes</h4>
        <img
          :src="require(`../assets/img/patchnotes/${this.patches[index]}`)"
        />
      </div>
      <button
        class="control-button button-right"
        :disabled="index >= this.patches.length - 1"
        @click="index++"
      ></button>
      <img
        class="patch-notes-overlay"
        :src="
          require(`../assets/img/Art/UserInterface/Core/PatchNotesViewerTopOverlay2.webp`)
        "
      />
    </div>
  </transition>
</template>
<script>
export default {
  name: "PatchNotes",
  data() {
    return {
      index: 0,
      patches: [
        "Patch23-5.webp",
        "Patch23-4.webp",
        "Patch23-3.webp",
        "Patch23-2.webp",
        "Patch23-1.webp",
        "Patch22-2.webp",
        "Patch22-1.webp",
        "Patch21.webp",
        "Patch20.webp",
        "Patch19.webp",
        "Patch18-2.webp",
        "Patch18-1.webp",
        "Patch17-2.webp",
        "Patch17-1.webp",
        "Patch16-2.webp",
        "Patch16-1.webp",
        "Patch15.webp",
        "Patch14-3.webp",
        "Patch14-2.webp",
        "Patch14-1.webp",
        "Patch13.webp",
        "Patch12.webp",
        "Patch11-2.webp",
        "Patch11-1.webp",
        "Patch10-2.webp",
        "Patch10-1.webp",
        "Patch9-2.webp",
        "Patch9-1.webp",
        "Patch8-2.webp",
        "Patch8-1.webp",
        "Patch7.1.webp",
        "Patch7.webp",
        "Patch6.2.webp",
        "Patch6.1.webp",
        "Patch6.webp",
        "Patch5.2.webp",
        "Patch5.1.webp",
        "Patch5.webp",
        "Patch4.webp",
        "Patch3.2-20170717.webp",
        "Patch3.1-20170712.webp",
        "Patch3-20170710.webp",
        "Patch2-20170705-2.webp",
        "Patch1-20170701.webp",
      ],
    }
  },
}
</script>
<style scoped lang="scss">
.patch-notes-container {
  position: absolute;
  width: 1024px;
  height: 602px;
  margin-left: 90px;
  z-index: 0;

  box-shadow: 5px 5px 5px 0px #000;

  display: flex;
  flex-direction: row;

  .patch-notes-image-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & > img {
      width: 975px;
      max-width: 975;
      height: 548px;
      max-height: 548px;
      margin-top: -12px;
      background-color: #ccc6b4;
    }
  }

  .patch-notes-overlay {
    position: absolute;
    width: 1024px;
    height: 602px;
    z-index: 2;
    left: 0;
    top: 0;
  }

  .control-button {
    z-index: 4;
    width: 21px;
    height: 41px;
    border: none;
    margin: auto;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &.button-left {
      transform: translate(14px, 2px);
      background-image: url("~@/assets/img/Art/UserInterface/Core/LeftButtonNormal.webp");

      &:hover {
        background-image: url("~@/assets/img/Art/UserInterface/Core/LeftButtonRollover.webp");
      }

      &:disabled {
        background-image: url("~@/assets/img/Art/UserInterface/Core/LeftButtonDisabled.webp");
      }
    }

    &.button-right {
      transform: translate(-12px, 2px);
      background-image: url("~@/assets/img/Art/UserInterface/Core/RightButtonNormal.webp");

      &:hover {
        background-image: url("~@/assets/img/Art/UserInterface/Core/RightButtonRollover.webp");
      }

      &:disabled {
        background-image: url("~@/assets/img/Art/UserInterface/Core/RightButtonDisabled.webp");
      }
    }
  }

  .text-heading {
    z-index: 3;
    padding-top: 14px;
    font-size: 21px;
    margin-bottom: 0;
    padding-right: 1px;
  }
}
</style>
