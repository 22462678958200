<template>
  <transition name="fade" @after-leave="selectedMap = null">
    <div class="maps-list-container" @click.stop>
      <h4 class="text-heading">Maps</h4>
      <div class="maps-list">
        <div
          v-for="map in this.maps"
          :key="map"
          @click="selectedMap = selectedMap == map ? null : map"
          class="maps-list-item"
        >
          <img :src="require(`../assets/img/maps/MapPics${map}.webp`)" class="maps-list-img" />
        </div>
      </div>
      <keep-alive>
        <mapdetails v-if="selectedMap" :map="mapsData[selectedMap]" :identifier="selectedMap" />
      </keep-alive>
    </div>
  </transition>
</template>
<script>
export default {
  name: "MapsList",
  data() {
    return {
      maps: require("../maps.json"),
      mapsData: require("../maps_data.json"),
      selectedMap: null
    }
  }
}
</script>
<style scoped lang="scss">
.maps-list-container {
  position: absolute;
  background-image: url("~@/assets/img/maps/MapsListBG.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 356px;
  height: 855px;
  min-width: 356px;
  min-height: 855px;
  margin-left: 90px;
  z-index: 0;

  .maps-list {
    display: flex;
    flex-direction: column;
    line-height: 0;
    margin-top: 28px;
    margin-bottom: 27px;
    text-align: left;
    padding-left: 29px;
    padding-right: 32px;

    height: 855px - 46px - 18px - 28px - 27px;
    justify-content: space-between;

    .maps-list-item {
      width: 290px;
      height: 63px;

      &:hover {
        cursor: pointer;
      }

      .maps-list-img {
        width: 290px;
        height: 63px;
      }
    }
  }

  .text-heading {
    padding-top: 18px;
    font-size: 25px;
    padding-right: 11px;
  }
}
</style>
