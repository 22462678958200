<template>
  <transition name="fade">
    <div class="map-details-container" @click.stop>
      <h4 class="text-heading">{{ map.n }}</h4>
      <img :src="require(`../assets/img/maps/MapThumb_${identifier}.webp`)" class="map-details-img" />
      <div class="map-details-info-container">
        <mapdetailsinfo type="size" :value="map.s" />
      </div>
      <span class="text-green-shadow">Resource distribution:</span>
      <div class="map-details-info-container">
        <mapdetailsinfo type="berries" :value="map.r.b" />
        <mapdetailsinfo type="huntables" :value="map.r.h" />
        <mapdetailsinfo type="wood" :value="map.r.w" />
        <mapdetailsinfo type="gold" :value="map.r.g" />
        <mapdetailsinfo type="stone" :value="map.r.s" />
        <mapdetailsinfo type="fish" :value="map.r.f" />
      </div>
      <div class="map-details-background"></div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "MapsList",
  props: {
    map: {
      type: Object,
      required: true
    },
    identifier: {
      type: String,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
.map-details-container {
  position: absolute;
  width: (632px * 0.7);
  height: (726px * 0.7);
  min-width: (632px * 0.7);
  min-height: (726px * 0.7);
  padding-left: 11px;
  padding-right: 5px;
  z-index: 0;
  top: 0;
  left: 100%;

  display: flex;
  flex-direction: column;

  .text-heading {
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: 22px;
    z-index: 2;
  }

  .text-green-shadow {
    font-size: 95%;
    font-family: "Ashley Crawford MT";
    text-align: center;
    text-shadow: 0 0 8px #00ff00;
    margin: 10px 0px;
    z-index: 2;
  }

  .map-details-info-container {
    display: table;
    z-index: 2;
    width: 100%;
    padding: 0 18px;

    & > span {
      display: table-row;
    }
  }

  .map-details-img {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    z-index: 0;
  }

  .map-details-background {
    position: absolute;
    width: (632px * 0.7);
    height: (726px * 0.7);
    min-width: (632px * 0.7);
    min-height: (726px * 0.7);
    background-image: url("~@/assets/img/maps/MapDetailsBG.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    left: 0;
    top: 0;
  }
}
</style>
