<template>
  <div class="map-details-info">
    <div>
      <img :src="icon" class="map-details-info-icon" />
      <span class="map-details-info-name">{{ text }}</span>
    </div>
    <div class="map-details-info-value" v-html="cValue"></div>
  </div>
</template>
<script>
export default {
  name: "MapsList",
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  },
  computed: {
    text() {
      switch (this.type) {
        case "size":
          return "Map Size:"
        case "berries":
          return "Berry Bushes:"
        case "huntables":
          return "Huntables:"
        case "wood":
          return "Trees:"
        case "gold":
          return "Gold Mines:"
        case "stone":
          return "Stonemines:"
        case "fish":
          return "Fish:"
        default:
          return "DEFAULT INFO TEXT:"
      }
    },
    cValue() {
      if (this.type === "size") {
        switch (this.value) {
          case 0:
            return "Small"
          case 1:
            return "Standard"
          case 2:
            return "Large"
          default:
            return "DEFAULT SIZE"
        }
      } else {
        switch (this.value) {
          case 0:
            return '<span class="text-negative">None</span>'
          case 0.5:
            return '<span class="text-average">Low</span>/<span class="text-negative">None</span>'
          case 1:
            return '<span class="text-average">Low</span>'
          case 2:
            return "Normal"
          case 3:
            return '<span class="text-positive">High</span>'
          case 4:
            return '<span class="text-positive">Very High</span>'
          default:
            return "DEFAULT"
        }
      }
    },
    icon() {
      switch (this.type) {
        case "size":
          return require("../assets/img/Art/UserInterface/InGame/MapSize.webp")
        case "berries":
          return require("../assets/img/Art/UserInterface/InGame/Berry.webp")
        case "huntables":
          return require("../assets/img/Art/UserInterface/InGame/ResourceFood_ua.webp")
        case "wood":
          return require("../assets/img/Art/UserInterface/InGame/ResourceWood_ua.webp")
        case "gold":
          return require("../assets/img/Art/UserInterface/InGame/ResourceGold_ua.webp")
        case "stone":
          return require("../assets/img/Art/UserInterface/InGame/ResourceStone_ua.webp")
        case "fish":
          return require("../assets/img/Art/UserInterface/InGame/Fish.webp")
        default:
          return null
      }
    }
  }
}
</script>
<style lang="scss">
.map-details-info {
  display: table-row;

  font-size: 95%;

  & > div {
    display: table-cell;
  }

  div,
  span,
  .map-details-info-value {
    text-shadow: 2px 0 2px #192b33;
    font-family: "Ashley Crawford MT";
    padding: 2px 0px;
    text-align: left;
  }

  div:last-child {
    text-align: right;
  }

  .map-details-info-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    vertical-align: middle;
  }
}
</style>
