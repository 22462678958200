import Vue from 'vue'
import App from './App.vue'
import router from './router'

const C = require('./constants.js')
Vue.config.productionTip = false

window.Vue = Vue
window.GlobalEvent = new Vue
// Load all components
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0].toLowerCase(), files(key).default))

// Global constants
// 'C' is a short name and doesn't conflict with
// the standard "constants"
Vue.mixin({
    data: function () {
        return {
            C: C
        }
    }
})

Vue.filter('highlight', function (words) {
    let str = words
    if (str) {
        for (let i = 0; i < C.highlight.length; i++) {
            str = str.replace(new RegExp('(^|\\(|\\s)(' + C.highlight[i] + ')(?=\\s|\\.|,|\\)|$)', 'g'), '$1<span class="text-highlight">$2</span>')
        }
    }
    return str
})

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
