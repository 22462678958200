let data
let highlight

try {
	data = require('./data.json')
} catch (err) {
	data = {}
}

try {
	highlight = require('./highlight.json')
} catch (err) {
	highlight = []
}

module.exports = {
	data: data,
	highlight: highlight,
	civs: {
		greek: 'C01',
		egypt: 'C02',
		persia: 'C04',
		celt: 'C03',
		roman: 'C05',
		babylon: 'C06',
		norse: 'C07',
		indian: 'C08',
	},
	hiddenCivs: [],
	civNames: {
		greek: 'Greeks',
		egypt: 'Egyptians',
		persia: 'Persians',
		celt: 'Celts',
		babylon: 'Babylonians',
		norse: 'Norse',
		roman: 'Romans',
		indian: 'Indians',
	},
	backgrounds: {
		economy: 'Art/UserInterface/CapitalTech/%CIV%/EconomyTechBackground%CIV%_ua.webp',
		military: 'Art/UserInterface/CapitalTech/%CIV%/MilitaryTechBackground%CIV%_ua.webp',
		utility: 'Art/UserInterface/CapitalTech/%CIV%/UtilityTechBackground%CIV%_ua.webp'
	},
	tabs: {
		economy: {
			unselected: 'Art/UserInterface/CapitalTech/EconomyTabUnselected_ua.webp',
			hover: 'Art/UserInterface/CapitalTech/EconomyTabRollover_ua.webp',
			clicked: 'Art/UserInterface/CapitalTech/EconomyTabClicked_ua.webp',
			selected: 'Art/UserInterface/CapitalTech/EconomyTabSelected_ua.webp',
			text: 'Economy'
		},
		military: {
			unselected: 'Art/UserInterface/CapitalTech/MilitaryTabUnselected_ua.webp',
			hover: 'Art/UserInterface/CapitalTech/MilitaryTabRollover_ua.webp',
			clicked: 'Art/UserInterface/CapitalTech/MilitaryTabClicked_ua.webp',
			selected: 'Art/UserInterface/CapitalTech/MilitaryTabSelected_ua.webp',
			text: 'Military'
		},
		utility: {
			unselected: 'Art/UserInterface/CapitalTech/UtilityTabUnselected_ua.webp',
			hover: 'Art/UserInterface/CapitalTech/UtilityTabRollover_ua.webp',
			clicked: 'Art/UserInterface/CapitalTech/UtilityTabClicked_ua.webp',
			selected: 'Art/UserInterface/CapitalTech/UtilityTabSelected_ua.webp',
			text: 'Utility'
		}
	},
	unitStats: {
		'Snare': { text: 'Snare', icon: 'US_Snare_ua.webp' },
		'MaxHitpoints': { text: 'Max Health', icon: 'US_MaxHealth_ua.webp' },
		'ArmorRanged': { text: 'Pierce Armor', icon: 'US_RangedArmor_ua.webp' },
		'LOS': { text: 'Line-of-sight', icon: 'US_SightRange_ua.webp' },
		'BuildPoints': { text: 'Build Time', icon: 'US_BuildTime_ua.webp' },
		'DamageHand': { text: 'Melee-Infantry DPS', icon: 'US_MeleeDPS_ua.webp' },
		'DamageBonusAbstractCavalry': { text: 'Bonus vs. Cavalry', icon: 'US_BonusDamagevsCavalry_ua.webp' },
		'DamageBonusEg_Cav_CamelRider': { text: 'Bonus vs. <span class="text-highlight">Camel Rider</span>', icon: 'US_BonusDamagevsCavalry_ua.webp' },
		'DamageBonusGr_Cav_Sarissophoroi': { text: 'Bonus vs. <span class="text-highlight">Sarissophoroi</span>', icon: 'US_BonusDamagevsCavalry_ua.webp' },
		'DamageBonusRo_Civ_Engineer': { text: 'Bonus vs. <span class="text-highlight">Engineer</span>', icon: 'US_BonusDamagevsVillager_ua.webp' },
		'DamageBonusIn_SacredCow': { text: 'Bonus vs. <span class="text-highlight">Sacred Cow</span>', icon: 'US_BonusDamagevsHuntable_ua.webp' },
		'ArmorHand': { text: 'Melee-Inf. Armor', icon: 'US_InfArmor_ua.webp' },
		'ArmorCavalry': { text: 'Melee-Cav. Armor', icon: 'US_CavArmor_ua.webp' },
		'ArmorSiege': { text: 'Crush Armor', icon: 'US_SeigeArmor_ua.webp' },
		'MaxVelocity': { text: 'Speed', icon: 'US_Speed_ua.webp' },
		'TrainPoints': { text: 'Train Time', icon: 'US_BuildTime_ua.webp' },
		'DamageBonusAbstractInfantry': { text: 'Bonus vs. Infantry', icon: 'US_BonusDamagevsInfantry_ua.webp' },
		'DamageBonusUnitTypeBldgStorehouse': { text: 'Bonus vs. Storehouse', icon: 'US_BonusDamagevsBuilding_ua.webp' },
		'DamageRanged': { text: 'Pierce DPS', icon: 'US_RangeDPS_ua.webp' },
		'MaxRange': { text: 'Attack Range', icon: 'US_WeaponRange_ua.webp' },
		'MaxRangeBuilding': { text: 'Attack Range vs. Buildings', icon: 'US_WeaponRange_ua.webp' },
		'DamageBonusAbstractArcher': { text: 'Bonus vs. Ranged', icon: 'US_BonusDamagevsRanged_ua.webp' },
		'DamageCavalry': { text: 'Melee-Cavalry DPS', icon: 'US_CavalryDPS_ua.webp' },
		'DamageBonusBuilding': { text: 'Bonus vs. Buildings', icon: 'US_BonusDamagevsBuilding_ua.webp' },
		'DamageSiege': { text: 'Crush DPS', icon: 'US_CrushDPS_ua.webp' },
		'DamageSiegeEnneris': { text: 'Crush DPS vs. Buildings', icon: 'US_CrushDPS_ua.webp' },
		'DamageArea': { text: 'Damage Area', icon: 'US_AreaDamage_ua.webp' },
		'HealingArea': { text: 'Healing Area', icon: 'HealArea.webp' },
		'DamageBonusShip': { text: 'Bonus vs. Ships', icon: 'US_BonusDamagevsShip_ua.webp' },
		'GatherAbstractFish': { text: 'Fish Gather Rate', icon: 'US_FoodGatherRate_ua.webp' },
		'MaxRangeHeal': { text: 'Healing Range', icon: 'US_WeaponRange_ua.webp' },
		'RateHeal': { text: 'Healing Rate (OoC)', icon: 'US_HealRate_ua.webp' },
		'RateHealInCombat': { text: 'Healing Rate', icon: 'US_HealRate_ua.webp' },
		'RateConvertableCavalry': { text: 'Convert Cavalry Rate', icon: 'US_ConvertRate_ua.webp' },
		'MaxRangeConvertConvertableCavalry': { text: 'Convert Cavalry Range', icon: 'US_WeaponRange_ua.webp' },
		'RateConvertableInfantry': { text: 'Convert Infantry Rate', icon: 'US_ConvertRate_ua.webp' },
		'MaxRangeConvertConvertableInfantry': { text: 'Convert Infantry Range', icon: 'US_WeaponRange_ua.webp' },
		'RateConvertableSiege': { text: 'Convert Siege Rate', icon: 'US_ConvertRate_ua.webp' },
		'MaxRangeConvertConvertableSiege': { text: 'Convert Siege Range', icon: 'US_WeaponRange_ua.webp' },
		'GatherCon_Res_BerryBush_C': { text: 'Berry Gather Rate', icon: 'US_FoodGatherRate_ua.webp' },
		'GatherStone': { text: 'Stone Gather Rate', icon: 'US_StoneGatherRate_ua.webp' },
		'GatherHerdable': { text: 'Herdable Gather Rate', icon: 'US_FoodGatherRate_ua.webp' },
		'GatherAbstractFarm': { text: 'Farm Gather Rate', icon: 'US_FoodGatherRate_ua.webp' },
		'FishGather': { text: 'Fish Gather Rate', icon: 'US_FoodGatherRate_ua.webp' },
		'GatherTree': { text: 'Wood Gather Rate', icon: 'US_WoodGatherRate_ua.webp' },
		'GatherHuntable': { text: 'Hunt. Gather Rate', icon: 'US_FoodGatherRate_ua.webp' },
		'GatherGold': { text: 'Gold Gather Rate', icon: 'US_GoldGatherRate_ua.webp' },
		'GatherAbstractFruit': { text: 'Berry Gather Rate', icon: 'US_FoodGatherRate_ua.webp' },
		'DamageBonusAbstractArtillery': { text: 'Bonus vs. Siege', icon: 'US_BonusDamagevsSiege_ua.webp' },
		'DamageBonusHuntable': { text: 'Bonus vs. Huntable', icon: 'US_BonusDamagevsHuntable_ua.webp' },
		'DamageBonusUnitTypeVillager1': { text: 'Bonus vs. Villager', icon: 'US_BonusDamagevsVillager_ua.webp' },
		'DamageBonusUnitTypeShipFishing1': { text: 'Bonus vs. Fishing Boat', icon: 'US_BonusDamagevsVillager_ua.webp' },
		'DamageBonusAbstractPriest': { text: 'Bonus vs. Priests', icon: 'US_BonusDamagevsPriests_ua.webp' },
		'RateStandardConvertable': { text: 'Conversion Rate', icon: 'US_ConvertRate_ua.webp' },
		'MaxRangeConvertStandardConvertable': { text: 'Conversion Range', icon: 'US_WeaponRange_ua.webp' },
		'MaxRangeConvert': { text: 'Conversion Range', icon: 'US_WeaponRange_ua.webp' },
		'MaxRangeChaos': { text: 'Chaos Range', icon: 'US_WeaponRange_ua.webp' },
		'RateChaos': { text: 'Chaos Rate', icon: 'US_ChaosRate_ua.webp' },
		'BuildLimit': { text: 'Build Limit', icon: 'BuildLimit.webp' },
		'SelfHeal': { text: 'Regen. Rate', icon: 'Self_Heal.webp' },
		'SplashDamageArmor': { text: 'Splash Damage Armor', icon: 'US_SplashDamageArmor_ua.webp' },
		'IgnoreArmor': { text: 'Ignore Armor', icon: 'US_IgnoreArmor_ua.webp' },
		'SnareResist': { text: 'Snare Resistance', icon: 'US_SnareResist_ua.webp' },
		'ConvertResist': { text: 'Conversion Resistance', icon: 'US_ConvertResist_ua.webp' },
		'AutoGatherFood': { text: 'Food Trickle Rate', icon: 'US_ResourceFood_ua.webp' },
		'AutoGatherGold': { text: 'Gold Trickle Rate', icon: 'US_ResourceGold_ua.webp' },
		'ShrineGather': { text: 'Shrine Gather Rate', icon: 'US_ResourceGold_ua.webp' }
	},
	statsOrder: {
		MaxHitpoints: 0, SelfHeal: 1,
		GatherAbstractFruit: 1, GatherHuntable: 2, GatherHerdable: 3, GatherAbstractFarm: 4, GatherTree: 5, GatherGold: 6, GatherStone: 7, GatherAbstractFish: 8, FishGather: 8,
		DamageHand: 9, DamageRanged: 9, DamageCavalry: 9, DamageSiege: 9, DamageSiegeEnneris: 9,
		DamageBonusAbstractCavalry: 10, DamageBonusAbstractInfantry: 10, DamageBonusAbstractArcher: 10, DamageBonusBuilding: 10, DamageBonusUnitTypeBldgStorehouse: 10,
		DamageBonusAbstractArtillery: 10, DamageBonusHuntable: 10, DamageBonusUnitTypeVillager1: 10, DamageBonusAbstractPriest: 10, DamageBonusShip: 10, DamageBonusEg_Cav_CamelRider: 10,
		DamageBonusGr_Cav_Sarissophoroi: 10, DamageBonusRo_Civ_Engineer: 10, DamageBonusUnitTypeShipFishing1: 10,
		Snare: 11, MaxRange: 12, MaxRangeBuilding: 13,
		RateStandardConvertable: 16, RateConvertableCavalry: 16, RateConvertableInfantry: 16, RateConvertableSiege: 16, RateChaos: 16,
		MaxRangeConvert: 17, MaxRangeChaos: 17,
		IgnoreArmor: 18,
		RateHeal: 12, RateHealInCombat: 12, HealingArea: 13, MaxRangeHeal: 14,
		DamageArea: 18,
		ArmorHand: 19, ArmorRanged: 20, ArmorCavalry: 21, ArmorSiege: 22, ConvertResist: 23, SnareResist: 24, SplashDamageArmor: 25,
		AutoGatherFood: 26, AutoGatherGold: 27, ShrineGather: 28, LOS: 29, MaxVelocity: 30, BuildLimit: 31, TrainPoints: 32, BuildPoints: 33
	}
}