import Vue from 'vue'
import Router from 'vue-router'
import TechTreeContainer from './views/TechTreeContainer.vue'
import TechTreeTab from './components/TechTreeTab.vue'

Vue.use(Router)

const routes = []
const C = require('./constants.js')

for(const civ in C.civs) {
    if (C.hiddenCivs.includes(civ)) {
        continue
    }
    routes.push({
        path: civ,
        name: civ,
        props: {civ: civ},
        redirect: civ + '/military',
        component: () => import(/* webpackChunkName: "about" */ './views/TechTree.vue'),
        children: [
            {path: 'military', name: civ + '.military', component: TechTreeTab, props: {civ: civ, tab: 'military'}},
            {path: 'economy', name: civ + '.economy', component: TechTreeTab, props: {civ: civ, tab: 'economy'}},
            {path: 'utility', name: civ + '.utility', component: TechTreeTab, props: {civ: civ, tab: 'utility'}}
        ]
    })
}

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: 'greek',
            component:  TechTreeContainer,
            children: routes
        }
    ]
})
